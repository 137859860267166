import React = require("react");
import { Card, Col, Container, Row } from "react-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import { RecipeFermentable, RecipeHop, RecipeYeast } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";


interface State {
    isLoading: boolean,
    hops: RecipeHop[],
    fermentables: RecipeFermentable[],
    yeasts: RecipeYeast[],
}

class IngredientsOnHandsComponent extends React.Component<Record<string,never>, State> {
    public constructor(props: Record<string,never>) {
        super(props);

        this.state = {
            isLoading: true,
            hops: [],
            fermentables: [],
            yeasts: [],
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getPlannedRecipesIngredients().then(resp => {
            this.setState({
                isLoading: false,
                hops: resp.hops,
                fermentables: resp.fermentables,
                yeasts: resp.yeasts,
            });
        }, err => {throw err;});
    }

    public renderRecipes() {
        const { hops, fermentables, yeasts } = this.state;

        if((hops.length + fermentables.length) === 0) {
            return (<Row>
                <Col>
                    There's no ingredients need...
                </Col>
            </Row>)
        }

        return (
            <>
                <Row className="strong-text">
                    <Col>
                        Fermentable(s) needed
                    </Col>
                    <Col>
                        Amount
                    </Col>
                </Row>
                {fermentables.map(fermentable => {
                    return (
                        <Row key={`fermentable-needed-${fermentable.fermentable.id}`}>
                            <Col>
                                {fermentable.fermentable.name}
                            </Col>
                            <Col>
                                {fermentable.amount.toFixed(0)} g
                            </Col>
                        </Row>
                    )
                })}
                <Row className="rowMarginTop strong-text">
                    <Col>
                        Hop(s) needed
                    </Col>
                    <Col>
                        Amount
                    </Col>
                </Row>
                {hops.map(hop => {
                    return (
                        <Row key={`hop-needed-${hop.hop.id}`}>
                            <Col>
                                {hop.hop.name}
                            </Col>
                            <Col>
                                {hop.amount.toFixed(1)} g
                            </Col>
                        </Row>
                    )
                })}
                <Row className="rowMarginTop strong-text">
                    <Col>
                        Yeast(s) needed
                    </Col>
                    <Col>
                        Amount
                    </Col>
                </Row>
                {yeasts.map(yeast => {
                    return (
                        <Row key={`yeast-needed-${yeast.yeast.id}`}>
                            <Col>
                                {yeast.yeast.name}
                            </Col>
                            <Col>
                                {yeast.amount.toFixed(1)}
                            </Col>
                        </Row>
                    )
                })}
            </>
        )
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <Card>
                <Card.Header>Ingredient(s) needed on hand</Card.Header>
                <Card.Body>
                    <Container fluid>
                        { isLoading ?
                            <LoadingComponent /> :
                            this.renderRecipes()
                        }
                    </Container>
                </Card.Body>
            </Card>
        )
    }

}

export default IngredientsOnHandsComponent;