import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";

import Grain from "@app/assets/img/ingredients/grain.svg"
import Button from "@app/components/common/Button";
import RowFormControlComponent from "@app/components/common/RowFormControlComponent";
import { Fermentable, FermentableType, fermentableTypeFriendlyString } from "@app/models/fermentable";
import { Producer } from "@app/models/producer";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    show: boolean,
    onHide: () => void,
}

interface State {
    name: string,
    fermentable_type: FermentableType,
    lovibond: string,
    point_per_pound_gallon: string,
    diastatic_power: string,
    isSubmitting: boolean,
    isCreated: boolean,
    producers: Producer[],
    producerId?: number,
    errors?: string[],
}

const getDefaultState = (): State => {
    return {
        name: "",
        fermentable_type: FermentableType.BaseMalt,
        lovibond: "0",
        point_per_pound_gallon: "0",
        diastatic_power: "0",
        isSubmitting: false,
        isCreated: false,
        producers: [],
        errors: [],
    };
}

class AddFermentableComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = getDefaultState();
    }

    public componentDidMount(): void {
        ServicesHelper.instance().ingredients().getProducers().then(resp => {
            this.setState({
                ...this.state,
                producers: resp.producers,
            })
        }, err => {throw err;})
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeLovibond = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            lovibond: evt.target.value,
        });
    }

    public onChangePPG = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            point_per_pound_gallon: evt.target.value,
        });
    }

    public onChangeDiastaticPower = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            diastatic_power: evt.target.value,
        });
    }

    public onChangeFermentableType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            fermentable_type: evt.target.value as FermentableType,
        });
    }

    public onChangeProducer = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        let producerId: number;

        if(evt.target.value === "none") {
            producerId = undefined;
        } else {
            producerId = Number.parseInt(evt.target.value, 10);
        }

        this.setState({
            ...this.state,
            producerId,
        });
    }

    public onClickAddFermentable = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { name, fermentable_type, lovibond, point_per_pound_gallon, diastatic_power, producerId} = this.state;
        const errors: string[] = [];

        const intLovibond = Number.parseInt(lovibond, 10);
        const floatPPG = Number.parseFloat(point_per_pound_gallon);
        const intDiastaticPower = Number.parseInt(diastatic_power, 10);

        if(Number.isNaN(intLovibond)) {
            errors.push("Invalid Lovibond");
        }

        if(Number.isNaN(floatPPG)) {
            errors.push("Invalid Point per pound gallon");
        }

        if(Number.isNaN(intDiastaticPower)) {
            errors.push("Invalid Diastatic power");
        }

        if(name.length === 0) {
            errors.push("Must specify a name");
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            });

            return;
        }

        const fermentable: Fermentable = {
            name,
            fermentable_type,
            lovibond: intLovibond,
            point_per_pound_gallon: floatPPG,
            diastatic_power: intDiastaticPower,
        };

        this.setState({
            ...this.state,
            errors: [],
            isSubmitting: true,
        });

        ServicesHelper.instance().ingredients().addFermentable(fermentable, producerId).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: true,
            });

            setTimeout(() => {
                this.props.onHide();
                this.setState(getDefaultState());
            }, 1500);
        }, err => {throw err;});
    }

    public render() {
        const { name,
                fermentable_type,
                lovibond,
                point_per_pound_gallon,
                diastatic_power,
                isSubmitting,
                isCreated,
                producers,
                errors
            } = this.state;
        const disabled = isSubmitting || isCreated
        const { show, onHide } = this.props;

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>
                        <img src={Grain} width="40px" height="40px" className="float-icon" />
                        New Fermentable
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col md={{span: 10, offset: 1}}>
                                {errors.length > 0 ? (
                                    <Alert variant="danger">
                                        <ul>
                                            {errors.map(err => { return (<li key={err}>{err}</li>) })}
                                        </ul>
                                    </Alert>
                                ) : null}
                                {isCreated ? <Alert variant="success">
                                                Fermentable created !
                                            </Alert> : null}
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Name"
                            placeholder="Fermentable name"
                            value={name}
                            onChange={this.onChangeName}
                            disabled={disabled}
                            labelWidth={4}
                            className="modal-form-row"
                        />
                        <Row className="modal-form-row">
                            <Col md="4">
                                <Form.Label>Type</Form.Label>
                            </Col>
                            <Col md="8">
                                <Form.Select defaultValue={fermentable_type} onChange={this.onChangeFermentableType} disabled={disabled}>
                                    <option value={FermentableType.BaseMalt}>{fermentableTypeFriendlyString(FermentableType.BaseMalt)}</option>
                                    <option value={FermentableType.CrystalMalt}>{fermentableTypeFriendlyString(FermentableType.CrystalMalt)}</option>
                                    <option value={FermentableType.DryMaltExtract}>{fermentableTypeFriendlyString(FermentableType.DryMaltExtract)}</option>
                                    <option value={FermentableType.LiquidMaltExtract}>{fermentableTypeFriendlyString(FermentableType.LiquidMaltExtract)}</option>
                                    <option value={FermentableType.RoastedMalt}>{fermentableTypeFriendlyString(FermentableType.RoastedMalt)}</option>
                                    <option value={FermentableType.Raw}>{fermentableTypeFriendlyString(FermentableType.Raw)}</option>
                                    <option value={FermentableType.Sugar}>{fermentableTypeFriendlyString(FermentableType.Sugar)}</option>
                                    <option value={FermentableType.Adjunct}>{fermentableTypeFriendlyString(FermentableType.Adjunct)}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Lovibond"
                            labelWidth={4}
                            placeholder="0"
                            value={lovibond}
                            onChange={this.onChangeLovibond}
                            disabled={disabled}
                            type="number"
                            className="modal-form-row"
                        />
                        <RowFormControlComponent
                            label="PPG"
                            labelWidth={4}
                            type="number"
                            placeholder="0"
                            value={point_per_pound_gallon}
                            onChange={this.onChangePPG}
                            disabled={disabled}
                            className="modal-form-row"
                        />
                        <RowFormControlComponent
                            label="Diastatic power"
                            labelWidth={4}
                            type="number"
                            placeholder="0"
                            value={diastatic_power}
                            onChange={this.onChangeDiastaticPower}
                            disabled={disabled}
                            className="modal-form-row"
                        />
                        <Row className="modal-form-row">
                            <Col md="4">
                                <Form.Label>Producer</Form.Label>
                            </Col>
                            <Col md="8">
                                <Form.Select defaultValue="none" onChange={this.onChangeProducer} disabled={disabled}>
                                    <option value="none">Not selected</option>
                                    {producers.map(producer => <option key={producer.id} value={producer.id}>{producer.name}</option>)}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="normal"
                        onClick={this.onClickAddFermentable}
                        disabled={disabled}
                    >
                        <Save /> Add
                    </Button>
                    <Button
                        variant="red"
                        onClick={onHide}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            );
    }
}

export default AddFermentableComponent;