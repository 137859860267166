import * as React from "react";
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { RouteComponentProps, withRouter } from 'react-router';
import { LinkContainer } from "react-router-bootstrap";

import AddBrewingSetupComponent from "@app/components/breweries/AddBrewingSetupComponent";
import EditBrewingSetupComponent from "@app/components/breweries/EditBrewingSetupComponent";
import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewingSetup } from '@app/models/brewing_setup';
import ServicesHelper from "@app/services/serviceshelper";

interface BrewingSetupsParams {
    id?: number,
    action?: string,
}

interface State {
    brewingSetups: BrewingSetup[],
    isLoading: boolean,
    showDeleteModal: boolean,
    brewingSetupIdToDelete?: number,
    errorMessage?: string,
}

type ComponentProps = RouteComponentProps<BrewingSetupsParams>;

class BrewingSetupsComponent extends React.Component<ComponentProps, State> {
    public constructor(props: ComponentProps) {
        super(props)
        this.state = {
            brewingSetups: [],
            isLoading: true,
            showDeleteModal: false,
        };
    }

    public componentDidUpdate(prevProps: ComponentProps, prevState: Readonly<State>, snapshot?: any): void {
        if(prevState.brewingSetupIdToDelete !== this.state.brewingSetupIdToDelete || prevProps.match.params.action !== this.props.match.params.action) {
            this.componentDidMount();
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                brewingSetups: resp.brewing_setups,
            });
        }, err => {throw err;});
    }

    public renderContent(action: string, id?: number) {
        if(action === "add") {
            return (<AddBrewingSetupComponent />);
        }
        else if(action === "edit" && id !== undefined) {
            return (<EditBrewingSetupComponent id={id} />);
        }
        else if (!action || action === "browse") {
            return this.renderBrewingSetupsTable()
        }

        return (<></>);
    }

    public onClickDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { brewingSetupIdToDelete, showDeleteModal } = this.state;
        if(!brewingSetupIdToDelete || !showDeleteModal) {
            return;
        }

        ServicesHelper.instance().brewing().deleteBrewingSetup(brewingSetupIdToDelete).then(resp => {
            if(resp.success) {
                this.setState({
                    ...this.state,
                    showDeleteModal: false,
                    brewingSetupIdToDelete: undefined,
                    errorMessage: undefined,
                });
            } else {
                this.setState({
                    ...this.state,
                    errorMessage: resp.error,
                });
            }
        }, err => {throw err;})
    }

    public onDeleteBrewingSetup = (brewingSetupId: number) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
            brewingSetupIdToDelete: brewingSetupId,
        });
    }

    public onHideDeleteModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            brewingSetupIdToDelete: undefined,
        });
    }

    public renderBrewingSetupsTable() {
        const { isLoading,
                brewingSetups,
                showDeleteModal,
                brewingSetupIdToDelete,
                errorMessage
        } = this.state;

        const brewingSetupToDelete = brewingSetupIdToDelete ?
            brewingSetups.find(brewingSetup => brewingSetup.id === brewingSetupIdToDelete) :
            null;

        return (
            <>
                <Row>
                    <Col className="tableHeader" md={{span: 2, offset: 4}}>Name</Col>
                    <Col className="tableHeader" md="2">Action(s)</Col>
                </Row>
                {
                    isLoading ?
                        <LoadingComponent /> :
                        brewingSetups.map(brewingSetup => {
                            return (<Row key={brewingSetup.id}>
                                <Col md={{span: 2, offset: 4}}>
                                    {brewingSetup.name}
                                </Col>
                                <Col md="2">
                                    <LinkContainer to={`/setups/edit/${brewingSetup.id}`}>
                                            <Button variant="light-blue">Edit</Button>
                                    </LinkContainer>
                                    <Button variant="red" onClick={() => {this.onDeleteBrewingSetup(brewingSetup.id)}}>Delete</Button>
                                </Col>
                            </Row>);
                    })
                }
                <Modal show={showDeleteModal}>
                    <Modal.Header>Delete brewing setup</Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this brewing setup ? <br />
                        <span>{brewingSetupToDelete?.name}</span>
                        {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="yellow" onClick={this.onHideDeleteModal} >
                            Cancel
                        </Button>
                        <Button variant="red" onClick={this.onClickDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    public render() {
        const action = this.props.match.params.action ?? "browse";
        const id = this.props.match.params.id;

        return (
            <Container fluid>
                <h2>Brewing Setups</h2>
                <Row>
                    <Col>
                        <LinkContainer to="/setups/add">
                                    <Button disabled={action === "add"}>
                                        <PlusCircle /> New
                                    </Button>
                        </LinkContainer>
                    </Col>
                </Row>
                {this.renderContent(action as string, id ? id as number : undefined)}
            </Container>
        )
    }
}

export default withRouter(BrewingSetupsComponent);