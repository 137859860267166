import { observer } from "mobx-react";
import * as React from "react";
import { Button, Col, Container, ProgressBar, Row, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { ExternalService } from "@app/models/external_service";
import { JobStatus } from "@app/models/job";
import ServicesHelper from "@app/services/serviceshelper";
import { NotificationsStore } from "@app/stores/notificationsstore";

interface Props {
    apiKey: string,
    externalService: ExternalService,
    notificationsStore: NotificationsStore,
    onNext: () => void,
}

interface State {
    eventId?: string,
    jobId?: number,
    started?: boolean,
    jobCompleted?: boolean,
    jobProgress?: number,
}

@observer
class ImportRecipesComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public onStart = (evt) => {
        const { started, eventId } = this.state;
        if (!started && !eventId) {
            this.setState({
                ...this.state,
                started: true,
            });

            ServicesHelper.instance().recipes().startBatchImport(this.props.apiKey).then(resp => {
                this.setState({
                    ...this.state,
                    started: true,
                    eventId: resp.event_id,
                }, () => { this.jobPoll() });
            }, err => {throw err;});
        }
    }

    public jobPoll() {
        const { eventId, jobCompleted, jobId } = this.state;
        if(eventId && !jobCompleted) {
            if(!jobId) {
                ServicesHelper.instance().jobs().getJobByEventId(eventId).then(resp => {
                    const respJobCompleted = resp.job?.status === JobStatus.Completed;

                    if(resp.job) {
                        this.setState({
                            ...this.state,
                            jobProgress: resp.job.data.progress,
                            jobCompleted: respJobCompleted,
                            jobId: resp.job.id,
                        });
                    }

                    if(!respJobCompleted) {
                        setTimeout(() => { this.jobPoll() }, 2000);
                    }
                }, err => {throw err;});
                return;
            }

            ServicesHelper.instance().jobs().getJob(jobId).then(resp => {
                const respJobCompleted = resp.job?.status === JobStatus.Completed;

                if(resp.job) {
                    this.setState({
                        ...this.state,
                        jobProgress: resp.job.data.progress,
                        jobCompleted: respJobCompleted,
                        jobId: resp.job.id,
                    });
                }

                if(!respJobCompleted) {
                    setTimeout(() => { this.jobPoll() }, 2000);
                }
            }, err => {throw err;});
        }
    }

    public renderJobInfo() {
        const { jobProgress, jobCompleted } = this.state;

        if (jobProgress === undefined) {
            return <>
                <Spinner animation="border" variant="warning" />
                Waiting on import to start...
            </>;
        }

        if (jobCompleted) {
            return <>Job Completed ! Continue to next step</>
        }

        return <>
            Recipe(s) import in progress...<br />
            <ProgressBar now={(jobProgress ?? 0)*100.00}/>
        </>;
    }

    public render() {
        const { eventId, started, jobCompleted } = this.state;

        return (<Container className="wizard-step-content">
            <Row className="wizard-step-title">
                <Col>Import your recipe(s)</Col>
            </Row>
            <Row>
                <Col>Click on the Start button to start the import, you can also skip this step if you want to only import brew session(s).</Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                    { eventId ?
                        this.renderJobInfo() :
                        <Button disabled={started} onClick={this.onStart}>Start Recipe(s) Import</Button>
                    }
                </Col>
            </Row>
            <Row className="wizard-step-buttons">
                <Col sm="1">
                    <LinkContainer to="/home">
                        <Button variant="danger">Cancel</Button>
                    </LinkContainer>
                </Col>
                <Col sm={{offset:9, span:1}}>
                    <Button
                        disabled={(started && !jobCompleted)}
                        variant="success"
                        onClick={() => {this.props.onNext()}}
                    >
                        { started ? "Continue" : "Skip" }
                    </Button>
                </Col>
            </Row>
        </Container>);
    }
}

export default ImportRecipesComponent;