import * as React from 'react';
import { Col } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

interface Props {
    span: number,
    offset?: number,
    label: string,
    name: string,
    currentSortBy: string,
    onClick: (name: string) => void,
}

class SortableColumnComponent extends React.Component<Props, Record<string,never>> {

    public constructor(props: Props) {
        super(props);
    }

    public renderCaret() {
        const { currentSortBy, name } = this.props;
        if(!currentSortBy.endsWith(name)) {
            return null;
        }

        return <>
        {
            currentSortBy.startsWith("-") ?
                <CaretUpFill /> :
                <CaretDownFill />
        }
        </>
    }

    public render() {
        const { span, offset, label, onClick, name } = this.props;

        return (
            <Col sm={{span, offset}} onClick={() => { onClick(name); }}>
                {label} {this.renderCaret()}
            </Col>
        );
    }
}

export default SortableColumnComponent;