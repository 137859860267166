import * as React from "react";
import { Modal } from "react-bootstrap";

import Button from "@app/components/common/Button";

interface Props {
    isVisible: boolean,
    title: string,
    body: string,
    onConfirm: () => void,
    onCancel: () => void,

    confirmCaption?: string,
    cancelCaption?: string,
}

class ConfirmationModalComponent extends React.Component<Props, Record<never, string>> {
    public constructor(props: Props) {
        super(props);
    }

    public onClickCancel = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onCancel();
    }

    public onClickConfirm = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onConfirm();
    }

    public render() {
        const {
            isVisible,
            title,
            body,
            confirmCaption,
            cancelCaption
        } = this.props;

        return <Modal show={isVisible}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="grey" onClick={this.onClickCancel} >
                    { cancelCaption ?? "No" }
                </Button>
                <Button variant="red" onClick={this.onClickConfirm} >
                    { confirmCaption ?? "Yes" }
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default ConfirmationModalComponent;