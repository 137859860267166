import * as React from "react";
import { Alert, Button, Col, Container, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import AddBrewingSetupComponent from "@app/components/breweries/AddBrewingSetupComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewingSetup } from "@app/models/brewing_setup";
import { ExternalService } from "@app/models/external_service";
import { JobStatus } from "@app/models/job";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    onNext: () => void,
    externalService: ExternalService,
    apiKey: string,
}

interface State {
    started?: boolean,
    jobCompleted?: boolean,
    eventId?: string,
    jobId?: number,
    jobProgress?: number,
    brewingSetups: BrewingSetup[],
    selectedBrewingSetupId?: number,
    isLoading: boolean,
}

class ImportBrewSessionsComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            brewingSetups: [],
            isLoading: true,
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            this.setState({
                ...this.state,
                brewingSetups: resp.brewing_setups,
                selectedBrewingSetupId: resp.brewing_setups.length > 0 ? resp.brewing_setups[0].id : undefined,
                isLoading: false,
            });
        }, err => {throw err;});
    }

    public onStart = (evt) => {
        const { started, eventId, selectedBrewingSetupId } = this.state;
        if (!started && !eventId) {
            this.setState({
                ...this.state,
                started: true,
            });

            ServicesHelper.instance().brewing().importBrewSessions(this.props.apiKey, selectedBrewingSetupId).then(resp => {
                this.setState({
                    ...this.state,
                    started: true,
                    eventId: resp.event_id,
                }, () => { this.jobPoll(); })
            }, err => {throw err;});
        }
    }

    public jobPoll() {
        const { eventId, jobCompleted, jobId } = this.state;
        if(eventId && !jobCompleted) {
            if(!jobId) {
                ServicesHelper.instance().jobs().getJobByEventId(eventId).then(resp => {
                    const respJobCompleted = resp.job?.status === JobStatus.Completed;

                    if(resp.job) {
                        this.setState({
                            ...this.state,
                            jobProgress: resp.job.data.progress,
                            jobCompleted: respJobCompleted,
                            jobId: resp.job.id,
                        });
                    }

                    if(!respJobCompleted) {
                        setTimeout(() => { this.jobPoll() }, 2000);
                    }
                }, err => {throw err;});
                return;
            }

            ServicesHelper.instance().jobs().getJob(jobId).then(resp => {
                const respJobCompleted = resp.job?.status === JobStatus.Completed;

                if(resp.job) {
                    this.setState({
                        ...this.state,
                        jobProgress: resp.job.data.progress,
                        jobCompleted: respJobCompleted,
                        jobId: resp.job.id,
                    });
                }

                if(!respJobCompleted) {
                    setTimeout(() => { this.jobPoll() }, 2000);
                }
            }, err => {throw err;});
        }
    }

    public renderJobInfo() {
        const { jobProgress, jobCompleted } = this.state;

        if (jobProgress === undefined) {
            return <>
                <Spinner animation="border" variant="warning" />
                Waiting on import to start...
            </>;
        }

        if (jobCompleted) {
            return <>Job Completed ! Continue to next step</>
        }

        return <>
            Brew session(s) import in progress...<br />
            <ProgressBar now={(jobProgress ?? 0)*100.00}/>
        </>;
    }

    public onCreateBrewingSetup = (brewingSetup: BrewingSetup) => {
        this.setState({
            ...this.state,
            brewingSetups: [brewingSetup],
            selectedBrewingSetupId: brewingSetup.id,
        });
    }

    public renderBrewingSetups() {
        const { brewingSetups, selectedBrewingSetupId } = this.state;

        if (brewingSetups.length === 0) {
            return <>
                <Row>
                    <Col>
                        <Alert variant="danger">
                            No brewing setup found! You will need to create one.
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <AddBrewingSetupComponent onCreated={this.onCreateBrewingSetup} />
                </Row>
            </>;
        }

        return <>
            <Row>
                <Col>Selected Brewing Setup</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Select defaultValue={selectedBrewingSetupId}>
                        { brewingSetups.map(brewingSetup =>
                            <option key={brewingSetup.id} value={brewingSetup.id}>{brewingSetup.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Row>
        </>;
    }

    public render() {
        const { eventId, started, jobCompleted, isLoading, selectedBrewingSetupId } = this.state;

        return (<Container className="wizard-step-content">
            <Row className="wizard-step-title">
                <Col>Import your brew session(s)</Col>
            </Row>
            <Row>
                <Col>Click on the Start button to start the import, you can also skip this step and complete the import wizard.</Col>
            </Row>
            <Row>
                <Col>
                    Choose with which Brewing Setup you want to associate the imported brew session(s)
                </Col>
            </Row>
            {
                isLoading ? <LoadingComponent /> : this.renderBrewingSetups()
            }
            <Row>
                <Col>
                    { eventId ?
                        this.renderJobInfo() :
                        <Button disabled={started || !selectedBrewingSetupId} onClick={this.onStart}>Start Brew Session(s) Import</Button>
                    }
                </Col>
            </Row>
            <Row className="wizard-step-buttons">
                <Col sm="1">
                    <LinkContainer to="/home">
                        <Button variant="danger">Cancel</Button>
                    </LinkContainer>
                </Col>
                <Col sm={{offset:9, span:1}}>
                    <Button
                        disabled={(started && !jobCompleted)}
                        variant="success"
                        onClick={() => {this.props.onNext()}}
                    >
                        { started ? "Continue" : "Skip" }
                    </Button>
                </Col>
            </Row>
        </Container>);
    }
}

export default ImportBrewSessionsComponent;