import { observer } from "mobx-react";
import * as React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";

import Wizard from "@app/assets/img/wizard/wizard.svg";
import ImportBrewSessionsComponent from "@app/components/importWizard/ImportBrewSessionsComponent";
import ImportRecipesComponent from "@app/components/importWizard/ImportRecipesComponent";
import { ExternalService } from "@app/models/external_service";
import { NotificationsStore } from "@app/stores/notificationsstore";

import StartStepComponent from "./StartStepComponent";
import "@app/assets/scss/components/WizardComponent.scss";

enum Step {
    Start,
    ImportingRecipes,
    ImportingBrewSessions,
    Completed,
}

interface Props {
    notificationsStore: NotificationsStore
}

interface State {
    currentStep: Step,
    apiKey: string,
    service: ExternalService
}

@observer
class ImportWizardComponent extends React.Component<Props, State>{
    public constructor(props: Props) {
        super(props);

        this.state = {
            currentStep: Step.Start,
            apiKey: "",
            service: ExternalService.BrewersFriend,
        }
    }

    public onStartStepNext = () => {
        this.setState({
            ...this.state,
            currentStep: Step.ImportingRecipes,
        });
    }

    public onImportRecipesNext = () => {
        this.setState({
            ...this.state,
            currentStep: Step.ImportingBrewSessions,
        });
    }

    public onImportBrewSessionsNext = () => {
        this.setState({
            ...this.state,
            currentStep: Step.Completed,
        });
    }

    public onChangeApiKey = (apiKey: string) => {
        this.setState({
            ...this.state,
            apiKey,
        });
    }

    public onChangeExternalService = (service: ExternalService) => {
        this.setState({
            ...this.state,
            service,
        });
    }

    public renderInner() {
        const { currentStep, apiKey, service } = this.state;
        switch(currentStep) {
            case Step.Start:
                return <StartStepComponent
                    onNext={this.onStartStepNext}
                    onChangeApiKey={this.onChangeApiKey}
                    onChangeExternalService={this.onChangeExternalService}
                    apiKey={apiKey}
                    externalService={service}
                />
            case Step.ImportingRecipes:
                return <ImportRecipesComponent
                    onNext={this.onImportRecipesNext}
                    apiKey={apiKey}
                    externalService={service}
                    notificationsStore={this.props.notificationsStore}
                />
            case Step.ImportingBrewSessions:
                return <ImportBrewSessionsComponent
                    onNext={this.onImportBrewSessionsNext}
                    apiKey={apiKey}
                    externalService={service}
                />
            case Step.Completed:
                return (<>
                    <Row>
                        <Col>
                            <Alert variant="success">Data Import Completed !</Alert>
                        </Col>
                    </Row>
                </>)
        }
    }

    public render() {
        return (
        <Container fluid>
            <Row>
                <Col sm={{offset: 2, span: 8}} className="wizard-container">
                    <Container fluid>
                        <Row className="wizard-title">
                            <Col>
                                <img src={Wizard} width="40px" height="40px" className="float-icon" />
                                <h4>Import Data from other Application</h4>
                            </Col>
                        </Row>
                        { this.renderInner() }
                    </Container>
                </Col>
            </Row>
        </Container>);
    }
}

export default ImportWizardComponent;