import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";

interface Props {
    label: string,
    value: string,
    placeholder?: string,
    disabled?: boolean,
    type?: string,
    onChange: (evt) => void,
    onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void,
    labelWidth?: number,
    offset?: number,
    className?: string,
}

class RowFormControlComponent extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { label, value, placeholder, disabled, type, onChange, onBlur, labelWidth, offset, className } = this.props;

        const computedOffset = offset ?? 0;
        const computedLabelWidth = (labelWidth ?? 6) - computedOffset;
        const computedControlWidth = 12 - computedLabelWidth - computedOffset;

        return (
            <Row className={className} >
                <Col md={{span: computedLabelWidth, offset: computedOffset}}>
                    <Form.Label>
                        {label}
                    </Form.Label>
                </Col>
                <Col md={computedControlWidth}>
                    <Form.Control
                        value={value}
                        placeholder={placeholder}
                        type={type ?? "text"}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled ?? false}
                    />
                </Col>
            </Row>
        )
    }
}

export default RowFormControlComponent;