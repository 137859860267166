import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewingSetup } from "@app/models/brewing_setup";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    onChange: (brewingSetupId: number) => void,
}

interface State {
    isLoading: boolean,
    brewingSetups: BrewingSetup[],
    selectedBrewingSetupId?: number,
}

class ChooseBrewingSetupComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            brewingSetups: [],
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                brewingSetups: resp.brewing_setups,
            });

            if (resp.brewing_setups.length > 0 ) {
                this.props.onChange(resp.brewing_setups[0].id);
            }
        }, err => {throw err;});
    }

    public onChangeBrewingSetup = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const brewingSetupId = Number.parseInt(evt.target.value, 10);

        this.setState({
            ...this.state,
            selectedBrewingSetupId: brewingSetupId,
        });

        this.props.onChange(brewingSetupId);
    }

    public render() {
        const { isLoading, brewingSetups, selectedBrewingSetupId } = this.state;

        return <Row>
                { isLoading ? <LoadingComponent /> :
                    <>
                        <Col>Brewing Setup: </Col>
                        <Col>
                            <Form.Select value={selectedBrewingSetupId} onChange={this.onChangeBrewingSetup}>
                            {
                                brewingSetups.map((brewingSetup) => <option key={brewingSetup.id} value={brewingSetup.id}>{brewingSetup.name}</option>)
                            }
                            </Form.Select>
                        </Col>
                    </>
                }
                </Row>;
    }

}

export default ChooseBrewingSetupComponent;