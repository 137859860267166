import React = require("react");
import { Card, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import { PersistedRecipe } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDate } from "@app/utils/date";

interface State {
    isLoading: boolean,
    recipes: PersistedRecipe[]
}

class RecipesComponent extends React.Component<Record<string,never>, State> {
    public constructor(props: Record<string,never>) {
        super(props);

        this.state = {
            isLoading: true,
            recipes: [],
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().recipes().getUserRecipes("", 0, 5, "-updated_at").then(resp => {
            this.setState({
                isLoading: false,
                recipes: resp.persisted_recipes
            })
        }, err => {throw err;})
    }

    public renderRecipes() {
        const { recipes } = this.state;

        if(recipes.length === 0) {
            return (<Row>
                <Col>
                    There's no recipe yet...
                </Col>
            </Row>)
        }

        return (
            <>
                <Row className="strong-text">
                    <Col>Name</Col>
                    <Col>Created At</Col>
                </Row>
                {recipes.map(recipe => {
                    return (
                    <LinkContainer key={recipe.id} to={`/recipes/view/${recipe.id}`}>
                        <Row>
                            <Col>
                                {recipe.name}
                            </Col>
                            <Col>
                                {timestampToDate(recipe.created_at)}
                            </Col>
                        </Row>
                    </LinkContainer>
                    )
                })}
            </>
        )
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <Card>
                <Card.Header>Latest Recipe(s)</Card.Header>
                <Card.Body>
                    <Container fluid>
                        { isLoading ?
                            <LoadingComponent /> :
                            this.renderRecipes()
                        }
                    </Container>
                </Card.Body>
            </Card>
        )
    }

}

export default RecipesComponent;