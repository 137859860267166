import * as React from 'react';
import "@app/assets/scss/components/Button.scss"

interface Props {
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    size?: "small" | "normal" | "large";
    variant?: "normal" | "blue" | "red" | "yellow" | "light-blue" | "grey";
    disabled?: boolean;
    type?: "submit" | "reset" | "button";
}

class Button extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { onClick, className, children, size, variant, disabled, type } = this.props;
        const disabledAttr = disabled ?? false;
        const sizeClass = `bm-btn-size-${size ?? "normal"}`;
        const variantClass = `bm-btn-variant-${variant ?? "normal"}`;
        const computedClassName = className ? `bm-btn ${sizeClass} ${variantClass} ${className}`: `bm-btn ${sizeClass} ${variantClass}`;

        return (
        <button
            type={type ?? "button"}
            onClick={onClick}
            className={computedClassName}
            disabled={disabledAttr}
        >
            {children}
        </button>);
    }
}

export default Button;