import React = require("react");
import { Col, Container, Row } from "react-bootstrap";

import BrewSessionsComponent from "@app/components/dashboard/BrewSessionsComponent";
import IngredientsOnHandsComponent from "@app/components/dashboard/IngredientsOnHandsComponent";
import RecipesComponent from "@app/components/dashboard/RecipesComponent";
import "@app/assets/scss/components/TableCommon.scss";


class DashboardComponent extends React.Component<Record<string,never>, Record<string,never>> {
    public constructor(props: Record<string,never>) {
        super(props);
    }

    public render() {
        return (
            <Container className="container-margin-top" fluid>
                <Row>
                    <Col>
                        <BrewSessionsComponent />
                    </Col>
                    <Col>
                        <IngredientsOnHandsComponent />
                    </Col>
                </Row>
                <Row className="rowMarginTop">
                    <Col>
                        <RecipesComponent />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default DashboardComponent;