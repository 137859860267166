import * as React from "react";
import { Container } from "react-bootstrap";
import { RouteComponentProps, withRouter } from 'react-router';

import BrowseBrewSessionsComponent from "./BrowseBrewSessionsComponent";
import NewBrewSessionComponent from "./NewBrewSessionComponent";
import ViewBrewSessionComponent from "./ViewBrewSessionComponent";

interface BrewsComponentParams {
    id?: number,
    action?: string,
}

type ComponentProps = RouteComponentProps<BrewsComponentParams>;

class BrewsComponent extends React.Component<ComponentProps, Record<string, never>> {
    public constructor(props: ComponentProps) {
        super(props);
    }

    public renderBrewSessionsTable(currentPage?: number) {
        return (<BrowseBrewSessionsComponent currentPage={currentPage} />);
    }

    public renderNewBrewSession(recipeId: number) {
        return (
            <NewBrewSessionComponent recipeId={recipeId} />
        );
    }

    public renderContent(action?: string, id?: number) {
        switch(action) {
            case "browse":
                return this.renderBrewSessionsTable(id);
            case "view":
                if (!id) {
                    return (<>This shouldn't happen</>);
                }

                return (<ViewBrewSessionComponent brewSessionId={id} />)
            case "new":
                return id ? this.renderNewBrewSession(id) : (<>This shouldn't happen</>);
        }

        return (<></>);
    }

    public render() {
        const { action, id } = this.props.match.params;

        return (
            <Container fluid className="h-100">
                <h2>Brew Sessions</h2>
                {this.renderContent((action ?? "browse") as string, id ? id as number : undefined)}
            </Container>
        );
    }

}

export default withRouter(BrewsComponent);