import React = require("react");
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Bucket } from "react-bootstrap-icons";
import { Redirect } from "react-router-dom";

import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewingSetup } from "@app/models/brewing_setup";
import { PersistedRecipe } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    recipeId: number;
}

interface State {
    isLoading: boolean,
    recipe?: PersistedRecipe,
    brewingSetups: BrewingSetup[],
    selectedBrewingSetupId?: number,
    isCreating: boolean,
    brewSessionId?: number,
    batchName: string,
}

class NewBrewSessionComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            brewingSetups: [],
            batchName: "",
            isCreating: false,
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            this.setState({
                ...this.state,
                brewingSetups: resp.brewing_setups,
            });

            ServicesHelper.instance().recipes().getRecipe(this.props.recipeId).then(recipeResp => {
                const selectedBrewingSetupId = recipeResp.brewing_setup?.id ?? this.state.brewingSetups[0].id;

                this.setState({
                    ...this.state,
                    recipe: recipeResp,
                    selectedBrewingSetupId,
                    batchName: recipeResp.name,
                    isLoading: false,
                });
            }, err => {throw err;});
        }, err => {throw err;});

        window.scrollTo(0, 0);
    }

    public onChangeBatchName = (evt) => {
        this.setState({
            ...this.state,
            batchName: evt.target.value,
        });
    }

    public render() {
        const { isLoading,
                recipe,
                brewingSetups,
                batchName,
                selectedBrewingSetupId,
                isCreating,
                brewSessionId
        } = this.state;

        if (brewingSetups.length === 0) {
            return (<Row>
                There's no brewing setup...
            </Row>);
        }

        return (<Row>
            { isLoading ?
                <LoadingComponent /> :
                <Container fluid>
                    <Row>
                        <Col>
                            <h4>New Brew Session</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:1, span:2}}>
                            Recipe:
                        </Col>
                        <Col sm="2">
                            {recipe.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:1, span:2}}>
                            Batch Name:
                        </Col>
                        <Col>
                            <Form.Control
                                disabled={isCreating}
                                value={batchName}
                                onChange={this.onChangeBatchName}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:1, span:2}}>
                            Brewing Setup:
                        </Col>
                        <Col>
                            <Form.Select
                                disabled={isCreating}
                                defaultValue={selectedBrewingSetupId}
                            >
                                {
                                    brewingSetups.map(brewingSetup => {
                                        return (<option key={brewingSetup.id} value={brewingSetup.id}>{brewingSetup.name}</option>);
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:3, span:2}}>
                            <Button onClick={this.onClickBrew} disabled={isCreating} >
                                <Bucket /> Brew!
                                { isCreating ? <LoadingComponent /> : null }
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        { brewSessionId ? <Redirect to={`/brews/view/${brewSessionId}`} /> : null }
                    </Row>
                </Container>
            }
        </Row>)
    }

    private onClickBrew = (evt) => {
        this.setState({
            ...this.state,
            isCreating: true,
        });

        ServicesHelper.instance().brewing().createBrewSession(this.props.recipeId, this.state.selectedBrewingSetupId, this.state.batchName).then(resp => {
            this.setState({
                ...this.state,
                brewSessionId: resp.brew_session_id,
            });
        }, err => {throw err;});
    }
}

export default NewBrewSessionComponent;