import React = require("react");
import { Button, Col, Row } from "react-bootstrap";
import { PlayCircle, Trash } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import { BrewSession, getPhaseFriendlyString } from "@app/models/brew_session";
import { timestampToDate, timestampToDateTime } from "@app/utils/date";

interface Props {
    brewSession: BrewSession,
    onDelete: (brewSession: BrewSession) => void,
}

class BrewSessionItemComponent extends React.Component<Props, Record<string, never>> {
    public constructor(props: Props) {
        super(props);
    }

    public onClickDelete = (evt) => {
        this.props.onDelete(this.props.brewSession);
    }

    public render() {
        const { brewSession } = this.props;

        return (<Row className="rowItem">
            <Col sm="4">
                {brewSession.batch_name}
            </Col>
            <Col sm="2">
                {getPhaseFriendlyString(brewSession.phase)}
            </Col>
            <Col sm="2">
                {timestampToDate(brewSession.created_at)}
            </Col>
            <Col sm="2">
                {timestampToDateTime(brewSession.updated_at)}
            </Col>
            <Col sm="2">
                <LinkContainer to={`/brews/view/${brewSession.id}`}>
                    <Button>
                        <PlayCircle /> View
                    </Button>
                </LinkContainer>
                |
                <Button variant="danger" onClick={this.onClickDelete}>
                    <Trash /> Delete
                </Button>
            </Col>
        </Row>)
    }

}

export default BrewSessionItemComponent;