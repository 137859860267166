import * as React from "react";
import { Alert } from "react-bootstrap";

import { AlertObject } from "@app/models/alert";

interface Props {
    alert: AlertObject;
}

class AlertComponent extends React.Component<Props, Record<string, never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { alert } = this.props;
        return <Alert variant={alert.variant()}>{alert.message()}</Alert>
    }
}

export default AlertComponent;