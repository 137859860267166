import * as React from 'react';
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
    currentPage: number,
    numberOfPages: number,
    pageLinkPrefix: string,
}

class PaginationComponent extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { currentPage, numberOfPages, pageLinkPrefix } = this.props;
        const pages: number[] = [];

        for(let i=1; i<=numberOfPages; i++) {
            pages.push(i);
        }

        return (<Pagination>
            {
                pages.map(page => {
                    return (
                        <LinkContainer key={page} to={`${pageLinkPrefix}${page}`}>
                            <Pagination.Item
                                disabled={currentPage === (page)}
                            >
                                {page}
                            </Pagination.Item>
                        </LinkContainer>
                    );
                })
            }
        </Pagination>)
    }
}

export default PaginationComponent;