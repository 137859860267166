import * as React from "react";
import { Card, Modal } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";

import { Image } from "@app/services/images";
import ServicesHelper from "@app/services/serviceshelper";

import "@app/assets/scss/components/CardImage.scss";

interface Props {
    image: Image,
}

interface State {
    expanded: boolean,
}

class CardImage extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    public toggle = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    public renderExpanded() {
        const { image } = this.props;
        const sessionKey = ServicesHelper.instance().getSessionKey();

        return <Modal show={true} dialogClassName="card-image-full-modal" onHide={this.toggle}>
            <Modal.Body>
                <img src={`${image.image_uri}?session_key=${sessionKey}`}/>
            </Modal.Body>
        </Modal>;
    }

    public renderThumbnail() {
        const { image } = this.props;
        const sessionKey = ServicesHelper.instance().getSessionKey();

        return <>{
            !image.thumbnail_uri ? <QuestionCircle className="card-image-icon" width="100px" height="100px" /> :
                <img src={`${image.thumbnail_uri}?session_key=${sessionKey}`}/>
        }</>;
    }

    public render() {
        const { expanded } = this.state;

        return <>
            <Card className="card-image" onClick={this.toggle}>
                { this.renderThumbnail() }
            </Card>
            { expanded ? this.renderExpanded() : null }
        </>
    }
}

export default CardImage;