import React = require("react");
import { Alert, Card, Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
import { ExclamationTriangle, JournalBookmark, Pencil, PlusCircle, ThermometerHalf, Trash } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import EditLogEntryComponent from "@app/components/breweries/EditLogEntryComponent";
import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewSession, BrewSessionLogEntry, BrewSessionPhase, getAttenuationInfo, getBrewDateFromLog, getBrewDayCompleteEvent, getLogEventFriendyString, getPhaseFriendlyString } from "@app/models/brew_session";
import { BrewingSetup } from "@app/models/brewing_setup";
import { PersistedRecipeWithRecipe } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";
import "@app/assets/scss/components/ViewBrewSessionComponent.scss";
import { getDaysDiffFromTimestamp, timestampToDateTime } from "@app/utils/date";
import { calculateRecipeABV, calculateRecipeFG, calculateRecipeFullGU, calculateRecipeOG, calculateRecipePreBoilGravity, calculateSRM, calculateTotalIBU } from "@app/utils/recipe_calculator";


interface Props {
    brewSessionId: number,
}

interface State {
    isLoading: boolean,
    brewSession?: BrewSession,
    recipe?: PersistedRecipeWithRecipe,
    brewingSetup?: BrewingSetup,
    isAddLogEntryModalVisible: boolean,
    brewLogIdToDelete?: number,
    brewLogToEdit?: BrewSessionLogEntry,
}

class ViewBrewSessionComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            isAddLogEntryModalVisible: false,
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewSession(this.props.brewSessionId).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                brewSession: resp.brew_session,
                brewingSetup: resp.brewing_setup,
                recipe: resp.recipe,
            });
        }, err => {throw err;});
    }

    public onClickAddLogEntry = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isAddLogEntryModalVisible: true,
        });
    }

    public onClickDeleteBrewLogEntry = (brewLogId: number) => {
        this.setState({
            ...this.state,
            brewLogIdToDelete: brewLogId,
        });
    }

    public onClickEditBrewLogEntry = (brewLog: BrewSessionLogEntry) => {
        this.setState({
            ...this.state,
            brewLogToEdit: brewLog,
        });
    }

    public renderBrewLogs(brewLogs: BrewSessionLogEntry[]) {
        const sortedBrewLogs = brewLogs.sort((a, b) => {
            return a.created_at - b.created_at;
        });

        const brewDayDate = getBrewDateFromLog(brewLogs);

        return (
            <>
                <Row className="log-table-header">
                    <Col sm="2">Event</Col>
                    <Col sm="1">Days</Col>
                    <Col sm="1">Volume</Col>
                    <Col sm="1">Gravity</Col>
                    <Col sm="1">
                        <ThermometerHalf />
                    </Col>
                    <Col sm="2">Date</Col>
                    <Col sm="2">Comment</Col>
                    <Col sm="2"></Col>
                </Row>
                {sortedBrewLogs.map(brewLog => {
                    return (
                    <Row key={brewLog.id} className="log-table-row">
                        <Col sm="2">{getLogEventFriendyString(brewLog.event_type)}</Col>
                        <Col sm="1">{brewDayDate ? `+${getDaysDiffFromTimestamp(brewDayDate, brewLog.created_at)} days` : null}</Col>
                        <Col sm="1" className="smaller-row-text">{brewLog.event_data.volume ? `${brewLog.event_data.volume.toFixed(1)} Liter(s)` : ""}</Col>
                        <Col sm="1">{
                            brewLog.event_data.final_gravity?.toFixed(3) ??
                            brewLog.event_data.original_gravity?.toFixed(3) ??
                            brewLog.event_data.gravity?.toFixed(3) ??
                            ""}
                        </Col>
                        <Col sm="1">{brewLog.event_data.temperature ? `${brewLog.event_data.temperature.toFixed(1)} °C` : ""}</Col>
                        <Col sm="2">{timestampToDateTime(brewLog.created_at)}</Col>
                        <Col sm="2" className="smaller-row-text">{brewLog.comment}</Col>
                        <Col sm="2">
                            <Button
                                variant="blue"
                                size="small"
                                onClick={(evt) => { this.onClickEditBrewLogEntry(brewLog) }}
                            >
                                <Pencil /> Edit
                            </Button>
                            <Button
                                className="ml-2"
                                variant="red"
                                size="small"
                                onClick={(evt) => { this.onClickDeleteBrewLogEntry(brewLog.id) }}
                            >
                                <Trash /> Delete
                            </Button>
                        </Col>
                    </Row>);
                })}
            </>
        )
    }

    public onHideDeleteModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            brewLogIdToDelete: undefined,
        });
    }

    public onClickDeleteBrewLog = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { brewLogIdToDelete, brewSession } = this.state;

        this.setState({
            ...this.state,
            brewLogIdToDelete: undefined,
        });

        ServicesHelper.instance().brewing().deleteBrewSessionLog(brewSession.id, brewLogIdToDelete).then(resp => {
            this.setState({
                ...this.state,
                brewSession: {
                    ...this.state.brewSession,
                    logs: this.state.brewSession.logs.filter(log => log.id !== brewLogIdToDelete),
                },
            });
        }, err => {throw err;})
    }

    public onHideEditLogEntry = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            brewLogToEdit: undefined,
            isAddLogEntryModalVisible: false,
        });
    }

    public updateBrewSessionLog(brewLog: BrewSessionLogEntry) {
        this.setState({
            ...this.state,
            brewLogToEdit: undefined,
        });

        ServicesHelper.instance().brewing().updateBrewSessionLog(this.state.brewSession.id, brewLog.id, brewLog).then(resp => {
            const logs: BrewSessionLogEntry[] = [];

            this.state.brewSession.logs.forEach(log => {
                if (log.id === brewLog.id) {
                    logs.push(brewLog);
                } else {
                    logs.push(log);
                }
            })

            this.setState({
                ...this.state,
                brewSession: {
                    ...this.state.brewSession,
                    logs,
                },
            });
        }, err => {throw err;});
    }

    public addBrewSesionLog(brewLog: BrewSessionLogEntry) {
        this.setState({
            ...this.state,
            isAddLogEntryModalVisible: false,
        });

        ServicesHelper.instance().brewing().addBrewSessionLog(this.state.brewSession.id, brewLog).then(resp => {
            const newBrewLogEntry: BrewSessionLogEntry = {
                ...brewLog,
                id: resp.id,
            };

            this.setState({
                ...this.state,
                brewSession: {
                    ...this.state.brewSession,
                    logs: this.state.brewSession.logs.concat([newBrewLogEntry]),
                }
            });
        }, err => {throw err;});
    }

    public onSaveLogEntry = (brewLog: BrewSessionLogEntry) => {
        if(brewLog.id !== undefined) {
            this.updateBrewSessionLog(brewLog);
        } else {
            this.addBrewSesionLog(brewLog);
        }
    }

    public renderDeleteModal() {
        const { brewLogIdToDelete } = this.state;

        return (
        <Modal show={brewLogIdToDelete !== undefined}>
            <Modal.Header>
                <Modal.Title>Delete Brew Session Log Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this entry ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="grey" onClick={this.onHideDeleteModal} >
                    No
                </Button>
                <Button variant="blue" onClick={this.onClickDeleteBrewLog} >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
        );
    }

    public isPhaseActive(brewPhase: BrewSessionPhase): boolean {
        const { brewSession } = this.state;
        return brewSession.phase === brewPhase;
    }

    public onClickPhase = (brewPhase: BrewSessionPhase) => {
        ServicesHelper.instance().brewing().updateBrewSessionPhase(this.state.brewSession.id, brewPhase).then(resp => {
            this.setState({
                ...this.state,
                brewSession: {
                    ...this.state.brewSession,
                    phase: brewPhase,
                }
            });
        }, err => {throw err;});
    }

    public render() {
        const { isLoading, brewSession, isAddLogEntryModalVisible, brewLogToEdit } = this.state;

        if (isLoading) {
            return (
                <Row>
                    <LoadingComponent />
                </Row>
            );
        }

        const recipe = this.state.recipe.recipe;
        const preboilGravity = calculateRecipePreBoilGravity(recipe);
        const originalGravity = calculateRecipeOG(recipe);
        const finalGravity = calculateRecipeFG(recipe);
        const abv = calculateRecipeABV(originalGravity, finalGravity);
        const ibu = calculateTotalIBU(recipe);
        const srm = calculateSRM(recipe);
        const squareSRM = srm > 40 ? 40 : srm;

        const fullGu = calculateRecipeFullGU(recipe);
        const brewDayCompleteEvent = getBrewDayCompleteEvent(brewSession.logs);
        const effiency = brewDayCompleteEvent ? (brewDayCompleteEvent?.event_data.original_gravity - 1.000)/(fullGu/1000) : null;
        const attenuationInfo = getAttenuationInfo(brewSession.logs);

        return (
            <Row>
                <Container fluid>
                    <Row>
                        <Col sm="4">
                            <h4>{brewSession?.batch_name}</h4>
                        </Col>
                        <Col sm={{offset: 4, span: 2}}>
                            <LinkContainer to={`/recipes/view/${recipe.id}`}>
                                <Button>
                                    <JournalBookmark /> View Recipe
                                </Button>
                            </LinkContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:1, span:2}}>
                            Original Gravity: <br />
                            {originalGravity.toFixed(3)}
                        </Col>
                        <Col sm="2">
                            Final Gravity: <br />
                            {finalGravity.toFixed(3)}
                        </Col>
                        <Col sm="2">
                            Alcohol by volume: <br />
                            {abv.toFixed(1)} %
                        </Col>
                        <Col sm="2">
                            IBU: <br />
                            {ibu.toFixed(1)}
                        </Col>
                        <Col sm="2">
                            SRM: <br />
                            {srm.toFixed(1)} <div className={`srm-${squareSRM.toFixed(0)} srm-square`}></div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm="4">
                            <Card className="h-100">
                                <Card.Header>Efficiency</Card.Header>
                                <Card.Body>
                                    Recipe Efficiency: {recipe.efficiency.toFixed(1)} %<br />
                                    Brew Efficiency: {effiency ? `${(effiency*100).toFixed(1)} %` : `N/A`} <br />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card className="h-100">
                                <Card.Header>Wort</Card.Header>
                                <Card.Body>
                                    Expected Gravity: {originalGravity.toFixed(3)} <br />
                                    Wort Gravity: {brewDayCompleteEvent ?
                                            brewDayCompleteEvent.event_data.original_gravity?.toFixed(3) ?? `N/A` :
                                            `N/A`} <br />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card className="h-100">
                                <Card.Header>Attenuation</Card.Header>
                                <Card.Body>
                                    Expected Attenuation: {recipe.yeast.attenuation.toFixed(1)} % <br />
                                    Attenuation: { attenuationInfo ? `${attenuationInfo.attenuation.toFixed(1)} %` : `N/A` } <br />
                                    Alcohol by volume: { attenuationInfo ? `${attenuationInfo.abv.toFixed(1)} %` : `N/A` } <br />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col sm={{offset:1, span:10}}>
                            <ListGroup horizontal>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.Planning) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.Planning)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.Planning)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.Brewing) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.Brewing)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.Brewing)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.PrimaryFermentation) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.PrimaryFermentation)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.PrimaryFermentation)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.SecondaryFermentation) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.SecondaryFermentation)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.SecondaryFermentation)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.Conditioning) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.Conditioning)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.Conditioning)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.Ready) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.Ready)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.Ready)}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={this.isPhaseActive(BrewSessionPhase.AllGone) ? "phase-active": ""}
                                    onClick={() => {this.onClickPhase(BrewSessionPhase.AllGone)}}
                                    as="button"
                                >
                                    {getPhaseFriendlyString(BrewSessionPhase.AllGone)}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="8">
                            <h4>Brew log(s)</h4>
                        </Col>
                        <Col sm={{offset:2, span:2}}>
                            <Button onClick={this.onClickAddLogEntry}>
                                <PlusCircle />
                                Add Log Entry
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Container fluid>
                            { brewSession.logs.length > 0 ?
                                this.renderBrewLogs(brewSession.logs) :
                                <Row>
                                    <Col sm={{offset:1, span:10}}>
                                        <Alert variant="warning">
                                            <ExclamationTriangle /> There's no log at the moment...
                                        </Alert>
                                    </Col>
                                </Row>}
                        </Container>
                    </Row>
                    <EditLogEntryComponent
                        brewLog={brewLogToEdit}
                        isVisible={brewLogToEdit !== undefined || isAddLogEntryModalVisible}
                        onHide={this.onHideEditLogEntry}
                        onSave={this.onSaveLogEntry}
                    />
                    {this.renderDeleteModal()}
                </Container>
            </Row>
        )
    }
}

export default ViewBrewSessionComponent;