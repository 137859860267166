import * as React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import FormControlComponent from "@app/components/common/FormControlComponent";
import { BrewingSetup, ChillingMethod, NewBrewingSetup, SetupType, friendlyChillingMethodString, friendlySetupTypeString } from "@app/models/brewing_setup";
import { FieldValidation, FieldValidations } from "@app/models/fields";
import { BrewingSetupField } from "@app/services/brewing";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    id: number;
}

interface State {
    isLoading: boolean,
    name: string,
    chillingMethod: ChillingMethod,
    setupType: SetupType,
    defaultBatchSize: string,
    kettleVolume: string,
    efficiency: string,
    mashTunVolume: string,
    kettleLosses: string,
    grainAbsorption: string,
    boilEvaporationRate: string,
    defaultBoilSize: string,
    isDefault: boolean,
    invalidFields: FieldValidations,
    brewingSetup?: BrewingSetup,
}

class EditBrewingSetupComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            name: "",
            chillingMethod: ChillingMethod.NoChill,
            setupType: SetupType.ThreeVessel,
            defaultBatchSize: "",
            kettleVolume: "",
            efficiency: "",
            mashTunVolume: "",
            kettleLosses: "",
            grainAbsorption: "",
            boilEvaporationRate: "",
            defaultBoilSize: "",
            isDefault: false,
            invalidFields: {},
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewingSetup(this.props.id).then(resp => {
            const brewingSetup = resp.brewing_setup;

            this.setState({
                ...this.state,
                isLoading: false,
                name: brewingSetup.name,
                chillingMethod: brewingSetup.chilling_method,
                setupType: brewingSetup.setup_type,
                defaultBatchSize: brewingSetup.default_batch_size.toFixed(1),
                kettleVolume: brewingSetup.kettle_volume.toFixed(1),
                efficiency: brewingSetup.default_efficiency.toFixed(0),
                mashTunVolume: brewingSetup.mash_tun_volume.toFixed(1),
                kettleLosses: brewingSetup.kettle_losses.toFixed(1),
                grainAbsorption: brewingSetup.grain_absorption.toFixed(1),
                boilEvaporationRate: brewingSetup.boil_evaporation_rate.toFixed(1),
                defaultBoilSize: brewingSetup.default_boil_size.toFixed(1),
                isDefault: brewingSetup.is_default,
                brewingSetup,
            })

        }, err => {throw err;})
    }

    public fieldValueIsAFloat(fieldName: string, fieldValue: string) : number | undefined {
        let tmpFieldValue = fieldValue;

        if(tmpFieldValue.indexOf(",") !== -1 && !tmpFieldValue.endsWith(",")) {
            tmpFieldValue = tmpFieldValue.replace(",", ".");
        }

        const value = Number(tmpFieldValue);

        if (isNaN(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${tmpFieldValue} is not a Number`, currentValue: tmpFieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public reduceFieldValidations(fieldName: string) : FieldValidations {
        const newFieldValidations: FieldValidations = {};
        const fieldValidations = this.state.invalidFields ?? {};

        Object.keys(fieldValidations).forEach(key => {
            if (key !== fieldName) {
                newFieldValidations[key] = fieldValidations[key];
            }
        })

        return newFieldValidations;
    }

    public getFieldValidation(fieldName: string) : FieldValidation | undefined {
        if(this.state.invalidFields) {
            if(this.state.invalidFields[fieldName]) {
                return this.state.invalidFields[fieldName];
            }
        }

        return undefined;
    }

    public onChangeName = (evt) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeBatchSize = (evt) => {
        this.setState({
            ...this.state,
            defaultBatchSize: evt.target.value,
        });

        const defaultBatchSize = this.fieldValueIsAFloat("defaultBatchSize", evt.target.value as string);
        if(defaultBatchSize !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("defaultBatchSize"),
                defaultBatchSize: evt.target.value,
            });
        }
    }

    public onChangeKettleVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            kettleVolume: evt.target.value,
        });

        const defaultBatchSize = this.fieldValueIsAFloat("kettleVolume", evt.target.value);
        if(defaultBatchSize !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("kettleVolume"),
                kettleVolume: evt.target.value,
            });
        }
    }

    public onChangeEfficiency = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            efficiency: evt.target.value,
        });

        const efficiency = this.fieldValueIsAFloat("efficiency", evt.target.value);
        if(efficiency !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("efficiency"),
                efficiency: evt.target.value,
            });
        }
    }

    public onChangeMashTunVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            mashTunVolume: evt.target.value,
        });

        const mashTunVolume = this.fieldValueIsAFloat("mashTunVolume", evt.target.value);
        if(mashTunVolume !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("mashTunVolume"),
                mashTunVolume: evt.target.value,
            });
        }
    }

    public onChangeKettleLosses = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            kettleLosses: evt.target.value,
        });

        const kettleLosses = this.fieldValueIsAFloat("kettleLosses", evt.target.value);
        if(kettleLosses !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("kettleLosses"),
                kettleLosses: evt.target.value,
            });
        }
    }

    public onChangeGrainAbsorption = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            grainAbsorption: evt.target.value,
        });

        const grainAbsorption = this.fieldValueIsAFloat("grainAbsorption", evt.target.value);
        if(grainAbsorption !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("grainAbsorption"),
                grainAbsorption: evt.target.value,
            });
        }
    }

    public onChangeBoilEvaporationRate = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            boilEvaporationRate: evt.target.value,
        });

        const boilEvaporationRate = this.fieldValueIsAFloat("boilEvaporationRate", evt.target.value);
        if(boilEvaporationRate !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("boilEvaporationRate"),
                boilEvaporationRate: evt.target.value,
            });
        }
    }

    public onChangeBoilSoize = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            defaultBoilSize: evt.target.value,
        });

        const defaultBoilSize = this.fieldValueIsAFloat("defaultBoilSize", evt.target.value);
        if(defaultBoilSize !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("defaultBoilSize"),
                defaultBoilSize: evt.target.value,
            });
        }
    }

    public onChangeSetupType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        ServicesHelper.instance().brewing().updateBrewingSetupSetupType(this.props.id, evt.target.value as SetupType).then(resp => {return;}, err => {throw err;});

        this.setState({
            ...this.state,
            setupType: evt.target.value as SetupType,
        });
    }

    public onChangeChillingMethod = (evt: React.ChangeEvent<HTMLSelectElement>) => {

        ServicesHelper.instance().brewing().updateBrewingSetupChillingMethod(this.props.id, evt.target.value as ChillingMethod).then(resp => {return;}, err => {throw err;});

        this.setState({
            ...this.state,
            chillingMethod: evt.target.value as ChillingMethod,
        });
    }

    public onBlurFloatField = (fieldName: string, brewingSetupField: BrewingSetupField, value: string) => {
        const floatValue = this.fieldValueIsAFloat(fieldName, value);
        if(floatValue !== undefined) {

            ServicesHelper.instance().brewing().updateBrewingSetupField(this.props.id, brewingSetupField, floatValue).then(resp => {return;}, err => {throw err;});

            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations(fieldName),
            });
        }
    }

    public onBlurName = (evt) => {
        ServicesHelper.instance().brewing().updateBrewingSetupName(this.props.id, this.state.name).then(resp => {return;}, err => {throw err;});
    }

    public onChangeIsDefault = (evt: React.ChangeEvent<HTMLInputElement>) => {
        ServicesHelper.instance().brewing().updateBrewingSetupIsDefault(this.props.id, evt.target.checked).then(resp => {
            return;
        }, err => { throw err; });

        this.setState({
            ...this.state,
            isDefault: evt.target.checked,
        });
    }

    public render() {
        const { name,
            isLoading,
            setupType,
            chillingMethod,
            defaultBatchSize,
            kettleVolume,
            mashTunVolume,
            kettleLosses,
            grainAbsorption,
            boilEvaporationRate,
            defaultBoilSize,
            isDefault,
            efficiency
        } = this.state;

        if(isLoading) {
            return (<>Loading...</>)
        }

        return (
        <>
            <h4>Edit brewing setup</h4>
            <Container fluid>
                <Row>
                    <Col sm={{offset: 1, span: 2}}>
                        <LinkContainer to="/setups">
                            <Button>
                                <ArrowLeftCircle /> Back
                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Name:</Col>
                    <Col sm="3">
                        <FormControlComponent
                            value={name}
                            onChange={this.onChangeName}
                            onBlur={this.onBlurName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Setup Type:</Col>
                    <Col sm="3">
                        <Form.Select defaultValue={setupType} onChange={this.onChangeSetupType}>
                            <option value={SetupType.BrewInABag}>{friendlySetupTypeString(SetupType.BrewInABag)}</option>
                            <option value={SetupType.ThreeVessel}>{friendlySetupTypeString(SetupType.ThreeVessel)}</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Chilling Method:</Col>
                    <Col sm="3">
                        <Form.Select defaultValue={chillingMethod} onChange={this.onChangeChillingMethod}>
                            <option value={ChillingMethod.NoChill}>{friendlyChillingMethodString(ChillingMethod.NoChill)}</option>
                            <option value={ChillingMethod.Counterflow}>{friendlyChillingMethodString(ChillingMethod.Counterflow)}</option>
                            <option value={ChillingMethod.Immersion}>{friendlyChillingMethodString(ChillingMethod.Immersion)}</option>
                            <option value={ChillingMethod.IceBath}>{friendlyChillingMethodString(ChillingMethod.IceBath)}</option>
                            <option value={ChillingMethod.PlateChiller}>{friendlyChillingMethodString(ChillingMethod.PlateChiller)}</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Default Batch Size:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={defaultBatchSize}
                            onChange={this.onChangeBatchSize}
                            fieldValidation={this.getFieldValidation("defaultBatchSize")}
                            onBlur={(evt) => {this.onBlurFloatField("defaultBatchSize", BrewingSetupField.DefaultBatchSize, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Kettle volume:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={kettleVolume}
                            onChange={this.onChangeKettleVolume}
                            fieldValidation={this.getFieldValidation("kettleVolume")}
                            onBlur={(evt) => {this.onBlurFloatField("kettleVolume", BrewingSetupField.KettleVolume, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Efficiency:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={efficiency}
                            onChange={this.onChangeEfficiency}
                            fieldValidation={this.getFieldValidation("efficiency")}
                            onBlur={(evt) => {this.onBlurFloatField("efficiency", BrewingSetupField.DefaultEfficiency, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        %
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Mash Tun Volume:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={mashTunVolume}
                            onChange={this.onChangeMashTunVolume}
                            fieldValidation={this.getFieldValidation("mashTunVolume")}
                            onBlur={(evt) => {this.onBlurFloatField("mashTunVolume", BrewingSetupField.MashTunVolume, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Kettle Losses:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={kettleLosses}
                            onChange={this.onChangeKettleLosses}
                            fieldValidation={this.getFieldValidation("kettleLosses")}
                            onBlur={(evt) => {this.onBlurFloatField("kettleLosses", BrewingSetupField.KettleLosses, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Grain Absorption:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={grainAbsorption}
                            onChange={this.onChangeGrainAbsorption}
                            fieldValidation={this.getFieldValidation("grainAbsorption")}
                            onBlur={(evt) => {this.onBlurFloatField("grainAbsorption", BrewingSetupField.GrainAbsorption, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Boil Evaporation Rate:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={boilEvaporationRate}
                            onChange={this.onChangeBoilEvaporationRate}
                            fieldValidation={this.getFieldValidation("boilEvaporationRate")}
                            onBlur={(evt) => {this.onBlurFloatField("boilEvaporationRate", BrewingSetupField.BoilEvaporationRate, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liters / hour
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Default Boil Size:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={defaultBoilSize}
                            onChange={this.onChangeBoilSoize}
                            fieldValidation={this.getFieldValidation("defaultBoilSize")}
                            onBlur={(evt) => {this.onBlurFloatField("defaultBoilSize", BrewingSetupField.DefaultBoilSize, evt.target.value as string)}}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Is Default:</Col>
                    <Col sm="1" className="ml-4">
                        <Form.Check
                            checked={isDefault}
                            onChange={this.onChangeIsDefault}
                        />
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

export default EditBrewingSetupComponent;