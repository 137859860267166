import moment = require("moment");
import React = require("react");
import { Alert, Form, Modal, Row } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";
import DateTimePicker from "react-datetime-picker";

import Button from "@app/components/common/Button";
import { BrewSessionLogEntry, BrewSessionLogEvent, getLogEventFriendyString } from "@app/models/brew_session";


interface Props {
    isVisible: boolean,
    onHide: (evt) => void,
    onSave: (logEntry: BrewSessionLogEntry) => void,
    brewLog?: BrewSessionLogEntry,
}

interface State {
    eventType?: BrewSessionLogEvent,
    comment: string,
    gravity?: string,
    temperature?: string,
    volume?: string,
    createdAt: number,
    isDirty: boolean,
    errors: string[],
}

class EditLogEntryComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            comment: "",
            isDirty: false,
            errors: [],
            createdAt: moment().unix(),
        }

        if(props.brewLog) {
            this.setStateFromBrewLog(props.brewLog);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        const { brewLog } = this.props;

        if(!brewLog && nextProps.brewLog) {
            this.setStateFromBrewLog(nextProps.brewLog);
        } else if(brewLog && brewLog.id !== nextProps.brewLog?.id) {
            this.setStateFromBrewLog(nextProps.brewLog);
        }
    }

    public setStateFromBrewLog(brewLog?: BrewSessionLogEntry) {
        this.setState({
            ...this.state,
            eventType: brewLog?.event_type,
            comment: brewLog?.comment,
            createdAt: brewLog?.created_at ?? moment().unix(),
            temperature: brewLog?.event_data.temperature?.toFixed(1),
            gravity: brewLog?.event_data.gravity?.toFixed(3) ??
                    brewLog?.event_data.final_gravity?.toFixed(3) ??
                    brewLog?.event_data.original_gravity?.toFixed(3),
            volume: brewLog?.event_data.volume?.toFixed(1),
            isDirty: false,
            errors: [],
        });
    }

    public onChangeEventType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        if (evt.target.value === "") {
            this.setState({
                ...this.state,
                eventType: null,
            });

            return;
        }

        const eventType = evt.target.value as BrewSessionLogEvent;
        this.setState({
            ...this.state,
            eventType,
            isDirty: true,
        });
    }

    public onChangeComment = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            comment: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeCreatedAt = (date) => {
        this.setState({
            ...this.state,
            createdAt: moment(date as string).unix(),
            isDirty: true,
        });
    }

    public onChangeGravity = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            gravity: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeTemperature = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            temperature: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            volume: evt.target.value,
            isDirty: true,
        })
    }

    public getGravityWording(): string {
        const eventType = this.state.eventType;

        switch(eventType) {
            case BrewSessionLogEvent.BrewDayComplete:
                return "Original Gravity";
            case BrewSessionLogEvent.FermentationComplete:
                return "Final Gravity";
            default:
                return "Gravity";
        }
    }

    public renderInnerSection() {
        if (!this.state.eventType) {
            return (<></>);
        }

        const { gravity, volume, temperature } = this.state;

        return (<>
            <Row>
                <Form.Group className="ml-3">
                    <Form.Label>{this.getGravityWording()}:</Form.Label>
                    <Form.Control value={gravity ?? ""}  onChange={this.onChangeGravity} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="ml-3">
                    <Form.Label>Temperature:</Form.Label>
                    <Form.Control value={temperature ?? ""}  onChange={this.onChangeTemperature} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="ml-3">
                    <Form.Label>Volume:</Form.Label>
                    <Form.Control value={volume ?? ""}  onChange={this.onChangeVolume} />
                </Form.Group>
            </Row>
        </>);
    }

    public onClickSave = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { gravity, temperature, volume, comment, eventType, createdAt } = this.state;
        const errors: string[] = [];

        if (!eventType) {
            errors.push("Must select an event type");
            this.setState({
                ...this.state,
                errors,
            });
            return;
        }

        const entryGravity = gravity ? Number.parseFloat(gravity) : null;
        const entryTemperature = temperature ? Number.parseFloat(temperature) : null;
        const entryVolume = volume ? Number.parseFloat(volume) : null;

        if (gravity && Number.isNaN(entryGravity)) {
            errors.push("Invalid gravity");
        }

        if (temperature && Number.isNaN(entryTemperature)) {
            errors.push("Invalid temperature");
        }

        if (volume && Number.isNaN(entryVolume)) {
            errors.push("Invalid volume");
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            });
            return;
        }

        const logEntry: BrewSessionLogEntry = {
            ...this.props.brewLog ?? {},
            event_type: eventType,
            comment,
            created_at: createdAt,
            event_data: {
                volume: entryVolume,
                temperature: entryTemperature,
            },
        };

        if(eventType === BrewSessionLogEvent.BrewDayComplete) {
            logEntry.event_data.original_gravity = entryGravity;
        } else if(eventType === BrewSessionLogEvent.FermentationComplete) {
            logEntry.event_data.final_gravity = entryGravity;
        } else {
            logEntry.event_data.gravity = entryGravity;
        }

        this.setState({
            ...this.state,
            errors: [],
            isDirty: false,
        });

        this.props.onSave(logEntry);
    }

    public render() {
        const { isVisible, onHide, brewLog } = this.props;
        const { eventType, comment, createdAt, errors, isDirty } = this.state;
        return (
        <Modal show={isVisible}>
            <Modal.Header>
                <Modal.Title>
                    {
                        brewLog ? `Edit Brew Session Log Entry` : `Add Brew Session Log Entry`
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <>
                        {errors.length > 0 ? <Alert variant="danger">
                            {errors.map(error => {
                                return (
                                    <span key={error}>
                                        {error}
                                    </span>);
                            })}
                        </Alert> : null}
                    </>
                      { isDirty ?
                          <Alert variant="warning">
                            <Bell /> Changes made aren't saved
                          </Alert> : null }
                </Row>
                <Row>
                    <Form.Group className="ml-3">
                        <Form.Label>
                            Event Type:
                        </Form.Label>
                        <Form.Select onChange={this.onChangeEventType} value={eventType ?? ""}>
                            <option value="">Not Selected</option>
                            <option value={BrewSessionLogEvent.BrewDayComplete}>{getLogEventFriendyString(BrewSessionLogEvent.BrewDayComplete)}</option>
                            <option value={BrewSessionLogEvent.FermentationComplete}>{getLogEventFriendyString(BrewSessionLogEvent.FermentationComplete)}</option>
                            <option value={BrewSessionLogEvent.DryHopped}>{getLogEventFriendyString(BrewSessionLogEvent.DryHopped)}</option>
                            <option value={BrewSessionLogEvent.Racked}>{getLogEventFriendyString(BrewSessionLogEvent.Racked)}</option>
                            <option value={BrewSessionLogEvent.Packaged}>{getLogEventFriendyString(BrewSessionLogEvent.Packaged)}</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                {this.renderInnerSection()}
                <Row>
                    <Form.Group className="ml-3">
                        <Form.Label>Comment:</Form.Label>
                        <Form.Control as="textarea" value={comment} onChange={this.onChangeComment} />
                    </Form.Group>
                </Row>
                <Row className="mt-3">
                    <Form.Group className="ml-3">
                        <Form.Label>Created At:</Form.Label>
                        <DateTimePicker value={moment.unix(createdAt).toDate()} format="dd-MM-y HH:mm" onChange={this.onChangeCreatedAt} />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="grey" onClick={onHide} >
                    Cancel
                </Button>
                <Button variant="blue" onClick={this.onClickSave} >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        );
    }
}

export default EditLogEntryComponent;