import React = require("react");
import { Card, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { BrewSession, getPhaseFriendlyString } from "@app/models/brew_session";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDate } from "@app/utils/date";

import LoadingComponent from "../common/LoadingComponent";

interface State {
    isLoading: boolean,
    brewSessions: BrewSession[],
}

class BrewSessionsComponent extends React.Component<Record<string,never>, State> {
    public constructor(props: Record<string,never>) {
        super(props);

        this.state = {
            isLoading: true,
            brewSessions: [],
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().brewing().getBrewSessions("-updated_at", 5, 0).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                brewSessions: resp.brew_sessions,
            });
        }, err => {throw err;});
    }

    public renderBrewSessions() {
        const { brewSessions } = this.state;

        if(brewSessions.length === 0) {
            return (<Row>
                <Col>
                    There's no brew session yet...
                </Col>
            </Row>)
        }

        return (
            <>
                <Row className="strong-text">
                    <Col>Batch Name</Col>
                    <Col>Phase</Col>
                    <Col>Created At</Col>
                </Row>
                {brewSessions.map(brewSession => {
                    return (
                    <LinkContainer key={brewSession.id} to={`/brews/view/${brewSession.id}`}>
                        <Row>
                            <Col>
                                {brewSession.batch_name}
                            </Col>
                            <Col>
                                {getPhaseFriendlyString(brewSession.phase)}
                            </Col>
                            <Col>
                                {timestampToDate(brewSession.created_at)}
                            </Col>
                        </Row>
                    </LinkContainer>
                    )
                })}
            </>
        )
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <Card>
                <Card.Header>Latest Brew Session(s)</Card.Header>
                <Card.Body>
                    <Container fluid>
                        { isLoading ?
                            <LoadingComponent /> :
                            this.renderBrewSessions()
                        }
                    </Container>
                </Card.Body>
            </Card>
        )
    }
}

export default BrewSessionsComponent;