import * as React from 'react';
import { Form } from "react-bootstrap";

import { FieldValidation } from "@app/models/fields";
import "@app/assets/scss/components/FormControlComponent.scss"

interface Props {
    className?: string,
    fieldValidation?: FieldValidation,
    onChange?: (evt) => void,
    onBlur?: (evt) => void,
    size?: "sm" | "lg",
    value: string | number,
    htmlSize?: number,
}

class FormControlComponent extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { className, fieldValidation, onChange, size, value, onBlur, htmlSize } = this.props;
        let computedClassName = fieldValidation ? `fieldError` : ``;

        if(className) {
            computedClassName = computedClassName.length === 0 ? `${className}` : `${computedClassName} ${className}`;
        }

        return (<Form.Control
            size={size}
            value={fieldValidation?.currentValue ?? value}
            className={computedClassName}
            onChange={onChange}
            onBlur={onBlur}
            htmlSize={htmlSize}
        ></Form.Control>)
    }
}

export default FormControlComponent;