import * as React from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import FormControlComponent from "@app/components/common/FormControlComponent";
import { BrewingSetup, ChillingMethod, NewBrewingSetup, SetupType, friendlyChillingMethodString, friendlySetupTypeString } from "@app/models/brewing_setup";
import { FieldValidation, FieldValidations } from "@app/models/fields";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    onCreated?: (brewingSetup: BrewingSetup) => void;
}

interface State {
    name: string,
    isCreated: boolean,
    brewingSetupId?: number,
    chillingMethod: ChillingMethod,
    setupType: SetupType,
    defaultBatchSize: string,
    kettleVolume: string,
    efficiency: string,
    invalidFields: FieldValidations,
}

class AddBrewingSetupComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            isCreated: false,
            chillingMethod: ChillingMethod.NoChill,
            setupType: SetupType.ThreeVessel,
            defaultBatchSize: "23",
            kettleVolume: "35",
            efficiency: "70",
            invalidFields: {},
        };
    }

    public fieldValueIsAFloat(fieldName: string, fieldValue: string) : number | undefined {
        let tmpFieldValue = fieldValue;

        if(tmpFieldValue.indexOf(",") !== -1 && !tmpFieldValue.endsWith(",")) {
            tmpFieldValue = tmpFieldValue.replace(",", ".");
        }

        const value = Number(tmpFieldValue);

        if (isNaN(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${tmpFieldValue} is not a Number`, currentValue: tmpFieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public reduceFieldValidations(fieldName: string) : FieldValidations {
        const newFieldValidations: FieldValidations = {};
        const fieldValidations = this.state.invalidFields ?? {}

        Object.keys(fieldValidations).forEach(key => {
            if (key !== fieldName) {
                newFieldValidations[key] = fieldValidations[key];
            }
        })

        return newFieldValidations;
    }

    public getFieldValidation(fieldName: string) : FieldValidation | undefined {
        if(this.state.invalidFields) {
            if(this.state.invalidFields[fieldName]) {
                return this.state.invalidFields[fieldName];
            }
        }

        return undefined;
    }

    public onChangeName = (evt) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }


    public onChangeBatchSize = (evt) => {
        this.setState({
            ...this.state,
            defaultBatchSize: evt.target.value,
        });

        const defaultBatchSize = this.fieldValueIsAFloat("defaultBatchSize", evt.target.value as string);
        if(defaultBatchSize !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("defaultBatchSize"),
                defaultBatchSize: evt.target.value,
            });
        }
    }

    public onChangeKettleVolume = (evt) => {
        this.setState({
            ...this.state,
            kettleVolume: evt.target.value,
        });

        const defaultBatchSize = this.fieldValueIsAFloat("kettleVolume", evt.target.value as string);
        if(defaultBatchSize !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("kettleVolume"),
                kettleVolume: evt.target.value,
            });
        }
    }

    public onChangeEfficiency = (evt) => {
        this.setState({
            ...this.state,
            efficiency: evt.target.value,
        });

        const efficiency = this.fieldValueIsAFloat("efficiency", evt.target.value as string);
        if(efficiency !== undefined) {
            this.setState({
                ...this.state,
                invalidFields: this.reduceFieldValidations("efficiency"),
                efficiency: evt.target.value,
            });
        }
    }

    public onChangeSetupType = (evt) => {
        this.setState({
            ...this.state,
            setupType: evt.target.value as SetupType,
        });
    }

    public onChangeChillingMethod = (evt) => {
        this.setState({
            ...this.state,
            chillingMethod: evt.target.value as ChillingMethod,
        });
    }

    public onCreateButton = (evt) => {
        // todo handle error (name, float parsing)

        const brewingSetup: NewBrewingSetup = {
            name: this.state.name,
            setup_type: this.state.setupType,
            chilling_method: this.state.chillingMethod,

            default_batch_size: Number.parseFloat(this.state.defaultBatchSize),
            kettle_volume: Number.parseFloat(this.state.kettleVolume),
            default_efficiency: Number.parseFloat(this.state.efficiency),
        };

        ServicesHelper.instance().brewing().createBrewingSetup(brewingSetup).then(resp => {
            if(this.props.onCreated) {
                this.props.onCreated(resp.brewing_setup);
            }

            this.setState({
                ...this.state,
                isCreated: true,
                brewingSetupId: resp.brewing_setup.id,
            });
        }, err => {throw err;})
    }

    public render() {
        const { name, isCreated, brewingSetupId, setupType, chillingMethod, defaultBatchSize, kettleVolume, efficiency } = this.state;

        return (
        <>
            <h4>New brewing setup</h4>
            <Container fluid>
                {isCreated ?
                (<Row>
                    <Alert variant="success">
                        Setup created !
                        <Link to={`/setups/edit/${brewingSetupId}`}>
                            Click here to continue
                        </Link>
                    </Alert>
                </Row>) : null}
                <Row>
                    <Col sm={{offset: 1, span: 2}}>
                        <LinkContainer to="/setups">
                            <Button>
                                <ArrowLeftCircle /> Back
                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Name:</Col>
                    <Col sm="3">
                        <FormControlComponent
                            value={name}
                            onChange={this.onChangeName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Setup Type:</Col>
                    <Col sm="3">
                        <Form.Select defaultValue={setupType} onChange={this.onChangeSetupType}>
                            <option value={SetupType.BrewInABag}>{friendlySetupTypeString(SetupType.BrewInABag)}</option>
                            <option value={SetupType.ThreeVessel}>{friendlySetupTypeString(SetupType.ThreeVessel)}</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Chilling Method:</Col>
                    <Col sm="3">
                        <Form.Select defaultValue={chillingMethod} onChange={this.onChangeChillingMethod}>
                            <option value={ChillingMethod.NoChill}>{friendlyChillingMethodString(ChillingMethod.NoChill)}</option>
                            <option value={ChillingMethod.Counterflow}>{friendlyChillingMethodString(ChillingMethod.Counterflow)}</option>
                            <option value={ChillingMethod.Immersion}>{friendlyChillingMethodString(ChillingMethod.Immersion)}</option>
                            <option value={ChillingMethod.IceBath}>{friendlyChillingMethodString(ChillingMethod.IceBath)}</option>
                            <option value={ChillingMethod.PlateChiller}>{friendlyChillingMethodString(ChillingMethod.PlateChiller)}</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Default Batch Size:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={defaultBatchSize}
                            onChange={this.onChangeBatchSize}
                            fieldValidation={this.getFieldValidation("defaultBatchSize")}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Kettle volume:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={kettleVolume}
                            onChange={this.onChangeKettleVolume}
                            fieldValidation={this.getFieldValidation("kettleVolume")}
                        />
                    </Col>
                    <Col sm="1">
                        Liter(s)
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 3, offset: 1}}>Efficiency:</Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={efficiency}
                            onChange={this.onChangeEfficiency}
                            fieldValidation={this.getFieldValidation("efficiency")}
                        />
                    </Col>
                    <Col sm="1">
                        %
                    </Col>
                </Row>
                <Row>
                    <Col sm={{offset: 1, span: 2}}>
                        <Button onClick={this.onCreateButton}>
                            <PlusCircle /> Add
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

export default AddBrewingSetupComponent;