import React = require("react");
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { DashCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import BrewSessionItemComponent from "@app/components/breweries/BrewSessionItemComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import SortableColumnComponent from "@app/components/common/SortableColumnComponent";
import { BrewSession } from "@app/models/brew_session";
import ServicesHelper from "@app/services/serviceshelper";

import PaginationComponent from "../common/PaginationComponent";

interface State {
    brewSessions: BrewSession[],
    brewSessionToDelete?: BrewSession,
    isLoading: boolean,
    totalCount: number,
    sortBy: string,
}

interface Props {
    currentPage?: number,
}

class BrowseBrewSessionsComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            brewSessions: [],
            isLoading: true,
            sortBy: "-updated_at",
            totalCount: 0,
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevState.sortBy !== this.state.sortBy || prevProps.currentPage !== this.props.currentPage) {
            this.loadBrewSessions();
            window.scrollTo(0, 0);
        }
    }

    public loadBrewSessions() {
        const { sortBy } = this.state;
        const { currentPage } = this.props;

        ServicesHelper.instance().brewing().getBrewSessions(sortBy, 20, ((currentPage ?? 1) - 1) * 20).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                brewSessions: resp.brew_sessions,
                totalCount: resp.count,
            })
        }, err => {throw err;});
    }

    public componentDidMount(): void {
        this.loadBrewSessions();
    }

    public onDeleteBrewSession = (brewSession: BrewSession) => {
        this.setState({
            ...this.state,
            brewSessionToDelete: brewSession,
        });
    }

    public onHideDeleteModal = (evt) => {
        this.setState({
            ...this.state,
            brewSessionToDelete: undefined,
        });
    }

    public onClickDeleteBrewSession = (evt) => {
        const { brewSessionToDelete, brewSessions } = this.state;

        this.setState({
            ...this.state,
            brewSessionToDelete: undefined,
        });

        ServicesHelper.instance().brewing().deleteBrewSession(brewSessionToDelete.id).then(resp => {
            this.setState({
                ...this.state,
                brewSessions: brewSessions.filter(brewSession => brewSession.id !== brewSessionToDelete.id),
            });
        }, err => {throw err;});
    }

    public onClickSortableColumn = (name: string) => {
        const { sortBy } = this.state;
        let newSortBy: string;

        if(sortBy.endsWith(name)) {
            newSortBy = sortBy.startsWith("-") ? `${name}`: `-${name}`;
        } else {
            newSortBy = name;
        }

        this.setState({
            ...this.state,
            sortBy: newSortBy,
        });
    }

    public render() {
        const { isLoading, brewSessions, brewSessionToDelete, sortBy, totalCount } = this.state;
        if (isLoading) {
            return (
                <Row>
                    <LoadingComponent />
                </Row>
                );
        }

        const numberOfPages = Math.ceil(totalCount / 20);

        return (
            <>
                <Container className="tableContainer" fluid>
                    <Row className="tableHeader">
                        <SortableColumnComponent
                            onClick={this.onClickSortableColumn}
                            currentSortBy={sortBy}
                            span={4}
                            label="Batch Name"
                            name="batch_name"
                        />
                        <SortableColumnComponent
                            onClick={this.onClickSortableColumn}
                            currentSortBy={sortBy}
                            span={2}
                            label="Phase"
                            name="phase"
                        />
                        <SortableColumnComponent
                            onClick={this.onClickSortableColumn}
                            currentSortBy={sortBy}
                            span={2}
                            label="Created At"
                            name="created_at"
                        />
                        <SortableColumnComponent
                            onClick={this.onClickSortableColumn}
                            span={2}
                            currentSortBy={sortBy}
                            label="Updated At"
                            name="updated_at"
                        />
                        <Col sm="2">
                            -
                        </Col>
                    </Row>
                    {
                        brewSessions.length !== 0 ?
                            brewSessions.map(brewSession => <BrewSessionItemComponent
                                brewSession={brewSession}
                                onDelete={this.onDeleteBrewSession}
                                key={brewSession.id}
                            />) :
                            <Row className="rowItem">
                                <Col sm={{offset:1, span:8}}>
                                    <Alert variant="warning">
                                        <DashCircle /> There's no brew session yet...
                                    </Alert>
                                </Col>
                            </Row>
                    }
                    <Row className="paginationRow">
                        <Col>
                            <PaginationComponent
                                numberOfPages={numberOfPages}
                                currentPage={this.props.currentPage ?? 1}
                                pageLinkPrefix={`/brews/browse/`}
                            />
                        </Col>
                    </Row>
                </Container>
                <Modal show={brewSessionToDelete !== undefined}>
                    <Modal.Header>
                        <Modal.Title>Delete Brew Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this brew session ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onHideDeleteModal} >
                            No
                        </Button>
                        <Button variant="primary" onClick={this.onClickDeleteBrewSession} >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );

    }
}

export default BrowseBrewSessionsComponent;