import * as React from "react";
import { Spinner } from "react-bootstrap";

interface Props {
    className?: string,
}

class LoadingComponent extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { className } = this.props;

        return (
            <Spinner className={className} animation="border" role="status" />
        );
    }
}

export default LoadingComponent;