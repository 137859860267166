import * as React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { ExternalService, externalServicePrettyName } from "@app/models/external_service";

interface Props {
    onNext: () => void,
    onChangeApiKey: (apiKey: string) => void,
    onChangeExternalService: (externalService: ExternalService) => void,
    apiKey: string,
    externalService: ExternalService,
}

class StartStepComponent extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public onChangeService = (evt) => {
        this.props.onChangeExternalService(evt.target.value as ExternalService);
    }

    public onChangeApiKey = (evt) => {
        this.props.onChangeApiKey(evt.target.value as string);
    }

    public render() {
        const { apiKey, externalService } = this.props;

        return (<Container fluid className="wizard-step-content">
            <Row className="wizard-step-title">
                <Col>First Step</Col>
            </Row>
            <Row>
                <Col>This wizard will help you to import all your previous recipes, ingredients and brew sessions from another application.</Col>
            </Row>
            <Row>
                <Col>
                    Choose from which application you want to import data
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Select
                        defaultValue={externalService}
                        onChange={this.onChangeService}
                    >
                        <option value={ExternalService.BrewersFriend}>{externalServicePrettyName(ExternalService.BrewersFriend)}</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col>Enter your API Key for this application</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control
                        placeholder="api-key"
                        value={apiKey}
                        onChange={this.onChangeApiKey}
                    />
                </Col>
            </Row>
            <Row className="wizard-step-buttons">
                <Col sm="1">
                    <LinkContainer to="/home">
                        <Button variant="danger">Cancel</Button>
                    </LinkContainer>
                </Col>
                <Col sm={{offset:9, span:1}}>
                    <Button variant="success" disabled={!apiKey || apiKey?.length === 0} onClick={() => {this.props.onNext()}}>
                        Continue
                    </Button>
                </Col>
            </Row>
        </Container>)
    }
}

export default StartStepComponent;